export const parseHttpResponse = (response): any => ({
	success: response.status === 200,
	errors: response.error.errors,
	message: response.error.message
})

export const convertDT = (datetime) => datetime ? datetime.replace(' ', 'T') : datetime;

export const dateString = (yourDate) => {
  const offset = yourDate.getTimezoneOffset()
  yourDate = new Date(yourDate.getTime() - (offset*60*1000))
  return yourDate.toISOString().split('T')[0]
}

export const timeString = (yourDate) => {
  const offset = yourDate.getTimezoneOffset()
  yourDate = new Date(yourDate.getTime() - (offset*60*1000))
  return yourDate.toISOString().split('T')[1].substr(0, 5)
}
