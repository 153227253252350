import { ActionReducerMap, createFeatureSelector, StoreModule } from '@ngrx/store';

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { reducer as listReducer} from './list/reducer';
import { reducer as errorsReducer} from './errors/reducer';
import { FeatureEffects as ListEffects } from './list/effects';
import { FeatureState } from './settings';
import { ItemModalComponent } from '../components/item-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';

const reducers: ActionReducerMap<FeatureState> = {
    list: listReducer,
    errors: errorsReducer
}

@NgModule({
    declarations: [
        ItemModalComponent,
    ],
    imports: [
        NgbModalModule,
        ReactiveFormsModule,
        CommonModule,
        NgSelectModule,
        StoreModule.forFeature('utenti', reducers),
        EffectsModule.forFeature([ListEffects])
    ],
    entryComponents: [
        ItemModalComponent
    ]
})

export class FeatureStoreModule {
}


