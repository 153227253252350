import { ApiClient } from '../../core/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/api.service";
export class FeatureService {
    constructor(http) {
        this.http = http;
    }
    calendar({ date, type, escludiTrattamento = null }) {
        return this.http.get(`events?date=${date}&type=${type}${escludiTrattamento ? '&escludiTrattamento=' + escludiTrattamento : ''}`);
    }
    searchClient({ query }) {
        return this.http.get(`events?query=${query}`);
    }
    createEvent(formData) {
        return this.http.post(`events`, formData);
    }
    updateEvent(id, formData) {
        formData = Object.assign({}, formData, { '_method': 'PUT' });
        return this.http.post(`events/${id}`, formData);
    }
    deleteEvent(id, formData) {
        formData = Object.assign({}, formData, { '_method': 'DELETE' });
        return this.http.post(`events/${id}`, formData);
    }
    dashboard() {
        return this.http.get('dashboard');
    }
    appuntamentiNonPianificati() {
        return this.http.get('dashboard/appuntamenti-non-pianificati');
    }
    senzaAppuntamento() {
        return this.http.get('dashboard/senza-appuntamento');
    }
    checkupNonPianificati() {
        return this.http.get('checkup/pending');
    }
    nuovoCheckup(data) {
        return this.http.post('dashboard/nuovo-checkup', data);
    }
    getCalendarUrl() {
        return this.http.get('account/calendar-url');
    }
    getGoogleConnectionUrl() {
        return this.http.getEndpoint('calendar/connect');
    }
    getGoogleDisconnectionUrl() {
        return this.http.getEndpoint('calendar/disconnect');
    }
    getTrattamentiAttivi(categoria) {
        return this.http.get(`dashboard/${categoria}`);
    }
}
FeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.ɵɵinject(i1.ApiClient)); }, token: FeatureService, providedIn: "root" });
