
        <div class="DateTimePicker" [ngClass]="{'no-time': !this.time}" [formGroup]="form">
            <div class="DateTimePicker_Date">
                <dp-date-picker #dayPicker (onChange)="handleCalendarClose($event)" formControlName="date" theme="dp-material" placeholder="Seleziona data" [config]="datePickerConfig"></dp-date-picker>
            </div>
            <div *ngIf="time" class="DateTimePicker_Time">
                <select name="hour" formControlName="hour">
                    <option [ngValue]="null">Ora</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                </select>
                <select name="minute" formControlName="minute">
                    <option [ngValue]="null">Min</option>
                    <option value="00">00</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                </select>
            </div>
        </div>
    