import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-box',
  styles: [`
    .SearchResults {
      max-width: 400px;
      width: 100%;
      position: relative;
    }

    .SearchResults__Items {
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 100%;
      border: 1px solid #E5EAEE;
      border-radius: 0 0 0.42rem 0.42rem;
      background: #E5EAEE;
      padding: 15px;
      z-index: 5;
      /*margin-top: -5px;*/
      text-align: left;
    }

    .SearchResults__ItemsContainer {
      max-height: 200px;
      overflow: auto;
    }

    .SearchResults__Items button {
      appearance: none;
      border: none;
      background: none;
      display: block;
      padding: 0 0 5px 0;
      text-align: left;
      color: rgba(0, 0, 0, 0.7);
      transition: all 0.3s;
      white-space: nowrap;
      width: 100%;
    }

    .SearchResults__Items button:hover {
      color: rgba(0, 0, 0, 1);
    }

    .SearchResults__Items button:not(:last-child) {
      border-bottom: 1px solid #fff;
      margin-bottom: 10px;
    }

  `],
  template: `
    <div class="SearchResults mb-3">
      <input [formControl]="searchInput" type="search" class="form-control" placeholder="Cerca appuntamenti per cliente" (focus)="show = true" (blur)="show = false" />
      <div class="SearchResults__Items"  *ngIf="show && results.length">
        <div class="SearchResults__ItemsContainer" (mousedown)="$event.preventDefault()" (click)="show = false">
          <button *ngFor="let item of results" (click)="onItemClick.emit(item)">
            <ng-container *ngIf="item.data">
              {{ item.data | dateFormat: 'dd/MM/yyyy HH:mm'}} - {{ item.cliente }}: {{item.description}}
            </ng-container>
            <ng-container *ngIf="!item.data">
              Nessun appuntamento trovato.
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  `
})

export class SearchBoxComponent implements OnInit {

  searchInput: FormControl = new FormControl();
  show = false;
  results = [];
  @Input() onSearch = null;
  @Output() onItemClick = new EventEmitter();

  ngOnInit(): void {

    this.searchInput.valueChanges
      .pipe(
        tap(text => {
          if (text.length < 3)
            this.resetResults()
        }),
        filter(text => text.length > 2),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(text => this.onSearch(text))
      ).subscribe((result: any) => {
      this.show = true
      if (result.length === 0)
        this.results = [{data: null}];
      else
        this.results = result;

    })

  }

  resetResults() {
    this.results = [];
  }


}
